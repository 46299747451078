module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/src/components/layout.js","posts":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/src/components/post.js"},"rehypePlugins":[null,null],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-images","id":"3179094e-fd77-5406-8a61-f00fb6662fd4","name":"gatsby-remark-images","version":"6.5.2","modulePath":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":700,"showCaptions":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-external-links","id":"3f028b2b-d6f3-54a8-b4ca-c4c73947e2bc","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"rel":"nofollow"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-embed-video","id":"0c0afa03-c85c-523c-8e1b-71e186b47afe","name":"gatsby-remark-embed-video","version":"3.1.1","modulePath":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-embed-video/index.js","pluginOptions":{"plugins":[],"width":700,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"urlOverrides":[],"containerClass":"embedVideo-container"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/portfolio-gatsby/portfolio-gatsby","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/home/runner/work/portfolio-gatsby/portfolio-gatsby/src/components/SuperWrapper.js"},
    },{
      plugin: require('../node_modules/@uehreka/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47996879-1","head":false,"anonymize":false,"respectDNT":false,"pageTransitionDelay":0,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chris Uehlinger's Website","short_name":"Chris Uehlinger","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/pages/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"005bc301e1fb88a1405dc1d0bb8c3d5a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
